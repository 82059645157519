import { FoundContent } from '@/components/Css/FoundSection';
import {
  Render,
  Article,
  ChartContainer,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import DonutChartMobileComponent from '@/components/DonutChartMobile';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';

const ReportUnicef2017: React.FC = () => (
  <Render>
    <FoundContent>
      <Section>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">유니세프 예산 구성비</Tit>
          </h2>
        </SectionHeader>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2017 수입
            </Tit>
          </h3>
          <div className="m-hide">
            <ChartContainer>
              <DonutChartComponent
                centerText="<span style='font-size: 20px;'>총 수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>65억 7,700만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `65%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 59 },
                      { name: `민간 및 비정부 기구 지원금`, y: 26 },
                      { name: `기구간 조정`, y: 13 },
                      { name: `기타`, y: 2 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <div className="pc-hide">
            <ChartContainer>
              <DonutChartMobileComponent
                titleText="<span style='font-size: 16px;color:#1cabe2;'>총 수입</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>65억 7,700만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `50%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 59 },
                      { name: `민간 및 비정부 기구 지원금`, y: 26 },
                      { name: `기구간 조정`, y: 13 },
                      { name: `기타`, y: 2 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <TableContainer>
            <table className="tbl1">
              <colgroup>
                <col className="col1" />
                <col className="col2" />
                <col className="col3" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>금액(백만 달러)</th>
                  <th>비중</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>각국 정부 지원금</td>
                  <td>
                    <p className="price">3,870</p>
                  </td>
                  <td>
                    <p className="price">59%</p>
                  </td>
                </tr>
                <tr>
                  <td>민간 및 비정부 기구 지원금</td>
                  <td>
                    <p className="price">1,710</p>
                  </td>
                  <td>
                    <p className="price">26%</p>
                  </td>
                </tr>
                <tr>
                  <td>기구간 조정</td>
                  <td>
                    <p className="price">859</p>
                  </td>
                  <td>
                    <p className="price">13%</p>
                  </td>
                </tr>
                <tr>
                  <td>기타*</td>
                  <td>
                    <p className="price">138</p>
                  </td>
                  <td>
                    <p className="price">2%</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>계</strong>
                  </td>
                  <td>
                    <p className="price">657,700</p>
                  </td>
                  <td>
                    <p className="price">100%</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <NoticeBox>
            <ul className="blit-list">
              <li>
                <strong>기타 : </strong>이자 수익, 조달품 등을 포함합니다.
              </li>
            </ul>
          </NoticeBox>
        </Article>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2017 지출
            </Tit>
          </h3>
          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">총지출 구성비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>총 지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>58억 3,500만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 90.8 },
                        { name: `행정관리비`, y: 5.8 },
                        { name: `기타`, y: 2.6 },
                        { name: `특별비`, y: 0.7 },
                        { name: `유엔개발협력비`, y: 0.1 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div className="pc-hide">
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>58억 3,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 90.8 },
                        { name: `행정관리비`, y: 5.8 },
                        { name: `기타`, y: 2.6 },
                        { name: `특별비`, y: 0.7 },
                        { name: `유엔개발협력비`, y: 0.1 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사업비</td>
                    <td>
                      <p className="price">5,297</p>
                    </td>
                    <td>
                      <p className="price">90.8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>행정관리비</td>
                    <td>
                      <p className="price">337</p>
                    </td>
                    <td>
                      <p className="price">5.8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>기타</td>
                    <td>
                      <p className="price">155</p>
                    </td>
                    <td>
                      <p className="price">2.6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>특별비</td>
                    <td>
                      <p className="price">40</p>
                    </td>
                    <td>
                      <p className="price">0.7%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유엔개발협력비</td>
                    <td>
                      <p className="price">7</p>
                    </td>
                    <td>
                      <p className="price">0.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">583,500</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <NoticeBox>
              <ul className="blit-list">
                <li>모든 금액과 단위, 지출내역은 2017년 기준입니다.</li>
              </ul>
            </NoticeBox>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">분야별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>분야별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>54억 4,900만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `보건 (Health)`, y: 25 },
                        { name: `교육 (Education)`, y: 22 },
                        { name: `식수•위생 (WASH)`, y: 19 },
                        { name: `보호 (Child Protection)`, y: 13 },
                        { name: `영양 (Nutrition)`, y: 12 },
                        { name: `사회 통합 (Social Inclusion)`, y: 7 },
                        { name: `에이즈 (HIV/AIDS)`, y: 2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>54억 4,900만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `보건 (Health)`, y: 25 },
                        { name: `교육 (Education)`, y: 22 },
                        { name: `식수•위생 (WASH)`, y: 19 },
                        { name: `보호 (Child Protection)`, y: 13 },
                        { name: `영양 (Nutrition)`, y: 12 },
                        { name: `사회 통합 (Social Inclusion)`, y: 7 },
                        { name: `에이즈 (HIV/AIDS)`, y: 2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>보건 (Health)</td>
                    <td>
                      <p className="price">1,375</p>
                    </td>
                    <td>
                      <p className="price">25%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>교육 (Education)</td>
                    <td>
                      <p className="price">1,203</p>
                    </td>
                    <td>
                      <p className="price">22%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>식수•위생 (WASH)</td>
                    <td>
                      <p className="price">1,019</p>
                    </td>
                    <td>
                      <p className="price">19%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>보호 (Child Protection)</td>
                    <td>
                      <p className="price">690</p>
                    </td>
                    <td>
                      <p className="price">13%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>영양 (Nutrition)</td>
                    <td>
                      <p className="price">665</p>
                    </td>
                    <td>
                      <p className="price">12%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>사회 통합 (Social Inclusion)</td>
                    <td>
                      <p className="price">413</p>
                    </td>
                    <td>
                      <p className="price">7%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>에이즈 (HIV/AIDS)</td>
                    <td>
                      <p className="price">84</p>
                    </td>
                    <td>
                      <p className="price">2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">544,900</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">지역별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>지역별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>54억 4,900만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 47 },
                        { name: `중동/북아프리카`, y: 25 },
                        { name: `아시아`, y: 16 },
                        { name: `구소련연방/동유럽`, y: 5 },
                        { name: `지역간 조정사업`, y: 4 },
                        { name: `라틴아메리카/카리브해`, y: 3 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>54억 4,900만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 47 },
                        { name: `중동/북아프리카`, y: 25 },
                        { name: `아시아`, y: 16 },
                        { name: `구소련연방/동유럽`, y: 5 },
                        { name: `지역간 조정사업`, y: 4 },
                        { name: `라틴아메리카/카리브해`, y: 3 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사하라 이남 아프리카</td>
                    <td>
                      <p className="price">2,539</p>
                    </td>
                    <td>
                      <p className="price">47%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>중동/북아프리카</td>
                    <td>
                      <p className="price">1,367</p>
                    </td>
                    <td>
                      <p className="price">25%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>아시아</td>
                    <td>
                      <p className="price">865</p>
                    </td>
                    <td>
                      <p className="price">16%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>구소련연방/동유럽</td>
                    <td>
                      <p className="price">275</p>
                    </td>
                    <td>
                      <p className="price">5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>지역간 조정사업</td>
                    <td>
                      <p className="price">228</p>
                    </td>
                    <td>
                      <p className="price">4%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>라틴아메리카/카리브해</td>
                    <td>
                      <p className="price">173</p>
                    </td>
                    <td>
                      <p className="price">3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">544,900</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>

            <NoticeBox>
              <ul className="blit-list">
                <li>
                  유니세프 지출예산은 당해년도 회기 1~2년 전에 집행이사회로부터
                  승인을 받습니다.
                  <br />
                  이로 인해 당해년도의 수입과 지출의 차이가 발생하며, 당해년도
                  차액은 다음 해의 프로그램 예산에 반영됩니다.
                </li>
                <li>
                  각 구분 금액의 합은 반올림에 의해 총 액과 다를 수 있습니다.
                </li>
                <li>
                  출처 :&nbsp;
                  <a
                    href="https://www.unicef.org/reports/unicef-annual-report-2017"
                    className="link-blank"
                    target="_balnk"
                  >
                    &nbsp;UNICEF Annual Report 2017
                  </a>
                </li>
              </ul>
            </NoticeBox>
          </div>
        </Article>
      </Section>
    </FoundContent>
  </Render>
);
export default ReportUnicef2017;
